import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import Discount from "../components/Discount/Discount";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import HeroSecond from "../components/HeroSecond/HeroSecond";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import Data from "../utils/data";

const Likes = () => {
  const likePageQuery = Data()

  return (

    <>
      <Seo
        title={likePageQuery.strapiLikePage.likePageSeo.title}
        description={likePageQuery.strapiLikePage.likePageSeo.description}
      />
      <Layout>
        <main>
          <HeroSecond
            title={likePageQuery.strapiLikePage.likeHeroSection.heading}
            description={likePageQuery.strapiLikePage.likeHeroSection.description}
            image={
              likePageQuery.strapiLikePage.likeHeroSection.heroImage.localFile
                .childImageSharp.gatsbyImageData
            }
            brand={likePageQuery.strapiLikePage.brandImages}
          />
          <Discount from="likes" />
          <HappyFaces />
          <CustomerViews />
        </main>
      </Layout>
    </>
  );
};

export default Likes;